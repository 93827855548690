/* @docs https://github.com/philipwalton/flexbugs */

@import '~antd/dist/antd.css';
/* @import '~@mgcrea/antd/dist/@mgcrea/antd.css'; */

.ant-checkbox-wrapper + span,
.ant-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}

body {
  margin: 0;
  height: 100%;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body {
  user-select: none;
  overflow: hidden;
  width: 100%;
  position: fixed;
  background: white;
} */

#root {
  height: 100%;
  display: flex;
  min-height: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-table-thead > tr.ant-table-row-hover > td,
.ant-table-tbody > tr.ant-table-row-hover > td,
.ant-table-thead > tr:hover > td,
.ant-table-tbody > tr:hover > td {
  background: #e6f7ff !important;
}
.ant-time-picker-panel > .ant-time-picker-panel-inner > .ant-time-picker-panel-input-wrap {
  overflow: hidden;
}

.ant-upload-list-item .anticon-close {
  font-size: 14px;
  padding: 8px;
}

.ant-upload-list-picture-card .anticon-close,
.ant-upload-list-picture .anticon-close {
  right: 0;
  top: 0;
}
